<template>
  <div id="interface">
    <!-- <img id="voiceover" src="room/voiceover-01.png"/>
    <div id="buttons">
      <div id="column">
        <svg class="svg-hover"
          viewBox="-4 -4 100 100"
          xmlns="http://www.w3.org/2000/svg"
          height="100%"
          @click="$router.push('/AreWeStillDoingThat')"
        >
          <defs>
            <radialGradient id="gradient">
              <stop offset="0%"  stop-color="#dedede"/>
              <stop offset="100%" stop-color="#a3a3a3"/>
            </radialGradient>
          </defs>
          <circle cx="46" cy="46" r="46" stroke="#e0e0e0" stroke-width="3" fill="url(#gradient)"/>
          <path transform="scale(0.45) translate(20 20) rotate(0)" fill="#b59ca7" class="cls-1" d="M69.17,159.9C31,159.9,0,124,0,80a4.5,4.5,0,0,1,9,0c0,39.12,27,71,60.17,71s60.2-31.83,60.2-70.95c0-25.25-17.33-45.79-38.64-45.79S52.11,54.7,52.11,80c0,11.39,7.65,20.65,17.06,20.65S86.23,91.34,86.23,80a4.5,4.5,0,0,1,9,0c0,16.35-11.69,29.65-26.06,29.65S43.11,96.3,43.11,80c0-30.21,21.36-54.79,47.62-54.79S138.37,49.74,138.37,80C138.37,124,107.33,159.9,69.17,159.9Zm0-25.15c-26.25,0-47.61-24.58-47.61-54.8C21.56,35.87,52.59,0,90.73,0S159.9,35.87,159.9,80a4.5,4.5,0,1,1-9,0C150.9,40.83,123.91,9,90.73,9S30.56,40.83,30.56,80c0,25.25,17.32,45.8,38.61,45.8S107.78,105.2,107.78,80c0-11.39-7.65-20.65-17-20.65S73.67,68.56,73.67,80a4.5,4.5,0,0,1-9,0c0-16.35,11.69-29.65,26.06-29.65S116.78,63.6,116.78,80C116.78,110.17,95.42,134.75,69.17,134.75Z"/>/>
        </svg>
        <svg
          viewBox="-4 -4 100 100"
          xmlns="http://www.w3.org/2000/svg"
          height="100%"
          class="not-available"
        >
          <circle cx="46" cy="46" r="46" stroke="#e0e0e0" stroke-width="3" fill="url(#gradient)"/>
          <text fill="#e0e0e0" font-size="80" font-family="HumanKind" x="21" y="75">?</text>
        </svg>
        <svg
          viewBox="-4 -4 100 100"
          xmlns="http://www.w3.org/2000/svg"
          height="100%"
          class="not-available"
        >
          <circle cx="46" cy="46" r="46" stroke="#e0e0e0" stroke-width="3" fill="url(#gradient)"/>
          <text fill="#e0e0e0" font-size="80" font-family="HumanKind" x="21" y="75">?</text>
        </svg>
      </div>
      <div id="column">
        <svg
          viewBox="-4 -4 100 100"
          xmlns="http://www.w3.org/2000/svg"
          height="100%"
          class="not-available"
        >
          <circle cx="46" cy="46" r="46" stroke="#e0e0e0" stroke-width="3" fill="url(#gradient)"/>
          <text fill="#e0e0e0" font-size="80" font-family="HumanKind" x="21" y="75">?</text>
        </svg>
        <svg
          viewBox="-4 -4 100 100"
          xmlns="http://www.w3.org/2000/svg"
          height="100%"
          class="not-available"
        >
          <circle cx="46" cy="46" r="46" stroke="#e0e0e0" stroke-width="3" fill="url(#gradient)"/>
          <text fill="#e0e0e0" font-size="80" font-family="HumanKind" x="21" y="75">?</text>
        </svg>
        <svg
          viewBox="-4 -4 100 100"
          xmlns="http://www.w3.org/2000/svg"
          height="100%"
          class="not-available"
        >
          <circle cx="46" cy="46" r="46" stroke="#e0e0e0" stroke-width="3" fill="url(#gradient)"/>
          <text fill="#e0e0e0" font-size="80" font-family="HumanKind" x="21" y="75">?</text>
        </svg>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#interface {
  width: 140px;
  height: 370px;
  position: fixed;
  pointer-events:auto;
  bottom: 0;
  left: 0;
  z-index:10;
  padding:10px;
}

#buttons {
  height: 240px;
  display:flex;
  flex-direction:row;
}

#column {
  width: 50%;
  height: 100%;
}

svg {
  width:100%;
  height:auto;
}

.svg-hover:hover {
  cursor:pointer;
  transform: rotate(20deg);
}
/* circle {
} */

#voiceover {
  display:block;
  width:auto;
  height:140px;
  margin: 0 auto;
  padding-bottom:10px;
}

svg.not-available {
  opacity: 0.2;
}

</style>