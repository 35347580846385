<template>
  <div class="scene">
    <div class="elevator">
      <div class="elevator__face back">
        <div class="top"></div>
        <div class="bottom">
          <div class="side"></div>
            <div id="doors" @mouseenter="playElevatorSound()">
              <div class="door" @click="$router.push('/out-loud'); this.$store.commit('elevatorDoorOpen', false)"></div>
              <div class="door" @click="$router.push('/out-loud'); this.$store.commit('elevatorDoorOpen', false)"></div>
            </div>
          <div class="side"></div>
        </div>
      </div>
      <div class="elevator__face right">
        <div class="counter">
          <p id="count">...</p>
        </div>
      </div>
      <div class="elevator__face left">
        <div class="sticker"></div>
      </div>
      <div class="elevator__face top">
        <div class="lights" :class="{ 'lights-animation' : this.$store.state.activated }"></div>
      </div>
      <div class="elevator__face bottom"></div>
    </div>
  <audio id="elevator-sound" v-if="hoverSound != null">
    <source :src="baseUrl + hoverSound.id + '/' + hoverSound.url" type="audio/mpeg">
  </audio>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      hoverSound: null,
      baseUrl: process.env.VUE_APP_BASE_URL + "cms/project/media/entries/",
      token: "522ed707c8c8490f117c3d037998f601"
    }
  },
  computed: {
    userVolume() {
      return this.$store.state.volume;
    },
    activated() {
      return this.$store.state.activated;
    }
  },
  watch: {
    userVolume(newlevel) {
      let elevatorSound = document.getElementById('elevator-sound');
      elevatorSound.volume = newlevel;
    },
    activated() {
      let count = document.getElementById("count");
          let i = 21;
          setInterval(function () {
            if (i <= 21 && i > 0) {
              count.innerHTML = i;
              i--;
            } else {
            switch (i) {
              case 0:
                count.innerHTML = "-";
                i--;
                break;
              case -1:
                count.innerHTML = "/";
                i--;
                break;
              case -2:
                count.innerHTML = "|";
                i--;
                break;
              case -3:
                count.innerHTML = "\\";
                i=0;
                break;
            }
          }
        }, 2000);
      }
  },
  created() {
    this.getElevatorSoundEffect()
  },
  methods: {
    playElevatorSound() {
      let doors = document.getElementById('doors');
      let elevatorSound = document.getElementById('elevator-sound');
      elevatorSound.volume = this.userVolume;
      
      /*eslint-disable no-unused-vars*/
      elevatorSound.play().catch(e => {
      doors.addEventListener('click', () => {
        elevatorSound.play()
      }, { once: true })
      })
      /*eslint-enable no-unused-vars*/
    },
    async getElevatorSoundEffect() {
      axios({
        method:'get',
        url:`cms/api/entries?id=interface&options[collection]=true&options[filter][where][0][key]=fieldset&options[filter][where][0][operator]=eq&options[filter][where][0][value]=interface-sound&token=${this.token}`,
        baseURL: process.env.VUE_APP_BASE_URL,
      }).then(response => {
        this.hoverSound = Object.values(response.data.data)[0];
      })
    }
  }
};
</script>

<style>
.scene {
  /* position: relative; */
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: rgb(101, 101, 101);
  
  /* this centering is only the case with the narrow elevator space */
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.elevator {
  perspective: 800px;

  width: 100vh;
  height: 120vh;
  /* transform-style: preserve-3d;
  transform: translateZ(-100px); */
}

.elevator__face {
  position: absolute;
  width: 120vh;
  height: 120vh;
  color: white;
  text-align: center;
  box-sizing: border-box;
  background: rgb(106, 113, 118);
  background: linear-gradient(
    0deg,
    rgb(106, 113, 118) 0%,
    rgba(118, 126, 129, 1) 37%,
    rgba(194, 200, 201, 1) 52%,
    rgba(118, 126, 129, 1) 68%,
    rgb(106, 113, 118) 100%
  );
}

/* .elevator__face.right  { background: rgba(66, 66, 66, 1); }
.elevator__face.back   { background: #797e81; }
.elevator__face.left   { background: #797e81; }
.elevator__face.top    { background: hsla(0, 0%, 13%, 1); }
.elevator__face.bottom { background: hsla(0, 0%, 16%, 1); } */

.elevator__face.right {
  transform: rotateY(90deg) translateZ(40vh);
}
.elevator__face.back {
  transform: rotateY(180deg) translateZ(60vh);
}
.elevator__face.left {
  transform: rotateY(-90deg) translateZ(60vh);
}
.elevator__face.top {
  transform: rotateX(90deg) translateZ(60vh);
}
.elevator__face.bottom {
  transform: rotateX(-90deg) translateZ(60vh);
}

.elevator__face.back, .elevator__face.top, .elevator__face.bottom {
  width: 100vh;
}

.elevator.is-backface-hidden .elevator__face {
  backface-visibility: hidden;
}

.sticker {
  width: 90%;
  height: 100%;
  margin-top: 0%;
  right: 0px;
  transform: scaleY(130%);
  transform-origin: top;
  background-image: url('./../../assets/room/panne.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.lights {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  top: 10%;
  right: 0px;
  border: none;
  background-color:rgb(67, 71, 75);
  /* transform: scaleY(120%);
  transform-origin: top; */
  background-image: url('./../../assets/room/light.png');
}

.lights-animation {
  animation: lights 4s infinite;
}

@-webkit-keyframes lights {
  0% {
    filter: brightness(1);
  }
  50% {
   filter: brightness(1.4);
  }
  100% {
    filter: brightness(1);
  }
}
@keyframes lights {
  0% {
    filter: brightness(1);
  }
  50% {
   filter: brightness(1.4);
  }
  100% {
    filter: brightness(1);
  }
}



.elevator__face.back {
  display: flex;
  flex-direction: column;
}

/* .elevator__face.bottom {
  border: 2px solid rgb(24, 24, 24);
} */
/* .elevator__face.top {
  border: 2px solid rgb(44, 44, 44);
} */

.elevator__face.back .top {
  height: 10%;
}

.elevator__face.back .bottom {
  display: flex;
  flex-direction: row;
  height: 90%;
}

/* .elevator__face.back .bottom video{
  width:2px;
  height:100%;
  object-fit: cover;
} */

.elevator__face.back #doors {
  width: 85%;
  display: flex;
  flex-direction: row;
  padding: 1.2vw 1.2vw 0 1.2vw;
  position: relative;
  background: linear-gradient(
    0deg,
    rgb(99, 99, 104) 0%,
    rgba(118, 126, 129, 1) 68%,
    rgb(67, 71, 75) 100%
  );
}

.elevator__face.back .door {
  background: rgb(106, 113, 118);
  background: linear-gradient(
    0deg,
    rgb(85, 89, 94)  0%,
    rgba(118, 126, 129, 1) 37%,
    rgba(194, 200, 201, 1) 52%,
    rgba(118, 126, 129, 1) 68%,
    rgb(85, 89, 94) 100%
  );
  width: 50%;
  height:100%;
  transform: translateZ(100px);
  border: 20px solid rgb(112, 112, 116);
}


#doors:hover .door{
  cursor:var(--pointing-cursor);
  background: linear-gradient(
    10deg,
    rgb(85, 89, 94)  0%,
    rgba(118, 126, 129, 1) 37%,
    rgba(194, 200, 201, 1) 52%,
    rgba(118, 126, 129, 1) 68%,
    rgb(85, 89, 94) 100%
  );
}

.elevator__face.back .side {
  flex-grow: 1;
}

.elevator__face.right .counter {
  margin: 0 auto;
  width: 80px;
  margin-top: 35%;
  height: 60px;
  background-color: rgb(49, 40, 40);
  border-radius: 10px;
  border: 1px solid black;
}

.elevator__face.right .counter #count {
  font-family: 'HumanKind';
  color: red;
  position: relative;
  font-size: 40px;
  margin-top:2px;
}

.elevator__face.right .counter #count{
  transform: rotateY(-180deg);
}







/* for smaller screens */
@media all and (max-width:850px) {
  .elevator {
    perspective: 220px;
    width: 80vw;
    height: 100vw !important;
  }

  .elevator__face {
    width: 90vw;
    height: 90vw !important;
  }

  .elevator__face.right {
  transform: rotateY(90deg) translateZ(35vw);
  }
  .elevator__face.back {
    transform: rotateY(180deg) translateZ(45vw);
  }
  .elevator__face.left {
    transform: rotateY(-90deg) translateZ(45vw);
  }
  .elevator__face.top {
    transform: rotateX(90deg) translateZ(45vw);
  }
  .elevator__face.bottom {
    transform: rotateX(-90deg) translateZ(45vw);
  }

  .elevator__face.back, .elevator__face.top, .elevator__face.bottom {
    width: 80vw;
  }

  .elevator__face.back .door {
  border-width: 2.5vw;
}

  .sticker {
    transform: scaleY(180%);
  }
}
</style>