<template>
  <div id="tracking" :class="{ 'before-activation' : !activated }">
    <div id="container">
      <Elevator/>
    </div>
      <div id="overlay" v-show="activated">
        <!-- <img class="overlay" src="room/wit_layer.png"/> -->
        <!-- <Navigation next="/stage"/> -->
      </div>
      <Buttons v-show="activated"/>
  </div>
  <div id="sound-container">
    <!-- audio files will be appended here -->
  </div>
</template>

<script>
import Buttons from './Buttons.vue'
import Elevator from './Elevator.vue'
import axios from "axios";

export default {
  components: {
   Buttons, Elevator
  },
  data() {
    return {
      room: {
        width: "1",
        height: "1.2",
        title: "elevator"
      },
      cursorArea: null,
      center: null,
      overlay:null,
      soundCollection: null,
      token: "522ed707c8c8490f117c3d037998f601"
    }
  },

  beforeRouteLeave(to, from, next) {
    setTimeout( function() {
      next();
    }, 2000);
  },
  created() {
    this.fetchEntranceAudio();
  },
  computed: {
    activated() {
      return this.$store.state.activated;
    },
    userVolume() {
      return this.$store.state.volume;
    },
    imageUrl() {
      return this.room.image.url;
    },
    mobile() {
      return this.$store.state.mobile;
    }
  },
  watch: {
    activated(val) {
        if (val == true) {
          this.startAudioContext();
          this.navigation.fadeInAudio(this.soundCollection, this.userVolume);
        }
    },
    userVolume(newVol) {
      if (this.activated == true) {
        this.navigation.changeVolume(newVol, this.soundCollection);
      }
    }
  },
  mounted() {
    this.cursorArea = document.getElementById("tracking");
    this.navigation = new this.navigator(this.cursorArea);

    let container = document.getElementById("container");

    const myTimeout = setTimeout(function() {
      // this.transitionTimeOut = false;
      container.classList.add("smooth-navigation");
      clearTimeout(myTimeout);
    }.bind(this), 2200);
  },
  methods: {
    fetchEntranceAudio() {
      axios({
        method:'get',
        url:`cms/api/entries?id=spaces/elevator&options[collection]=true&options[filter][where][0][key]=fieldset&options[filter][where][0][operator]=eq&options[filter][where][0][value]=ambient-sound&token=${this.token}`,
        baseURL: process.env.VUE_APP_BASE_URL,
      })
        .then(response => {
          this.soundCollection = response.data.data;

          // running the navigation from the navigation class
          let container = document.getElementById("container");
          let overlay = document.getElementById("overlay");

          this.navigation.initRoom(container, overlay, this.room, this.mobile);

          // when coming from another space, check if activation is already set to true
          if (this.activated == true) {
            this.startAudioContext();
            this.navigation.fadeInAudio(
              this.soundCollection,
              this.userVolume
            );
          }
        }, error => {
          console.log("There are no sounds uploaded, every space requires at least one ambient sound. Error: " + error);
        });
    },
    startAudioContext() {
      // initation sounds
      var soundContainer = document.getElementById("sound-container");

      this.navigation.initSound(
        soundContainer,
        this.soundCollection,
        this.userVolume
      );
    },
  }
};
</script>

<style scoped>
#tracking {
  position:absolute;
  width: 100%;
  height:100%;
  /* opacity: 1;
  transition: opacity 3s; */
  animation: fade 2s;
}

img.overlay {
  width:100%;
  height:100%;
}

#container {
  animation: fade 3s;
}

#tracking.before-activation {
  pointer-events:none;
  /* opacity: 0.5; */
}

#overlay {
  position: absolute;
  top:0;
  width:100%;
  height:100%;
  z-index:100;
  pointer-events:none;
}

#container, #overlay {
  will-change: transform;
  object-position: center center;
  /* transition: transform 0.05s; */
}

@-webkit-keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* for smaller screens */
@media all and (max-width: 850px) {
  #tracking {
    overflow:hidden;
  }
}

</style>